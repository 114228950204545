import { actionType } from "./action";
export const InitialState = {
  ItembillingData: "",
  PurchaseData:"",
  SaleData:"",
  PrintPurchaseData:""
  
};

const SaleReducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionType.ITEM_FASTBILLING:
      return { ...state, ItembillingData: "" };
  case actionType.ITEM_FASTBILLING_SUCCESS:
      return { ...state, ItembillingData: action.payload };
  case actionType.ITEM_FASTBILLING_ERROR:
      return { ...state, ItembillingData: "error" };


      case actionType.PURCHASE_INSERT:
        return { ...state, PurchaseData: "" };
    case actionType.PURCHASE_INSERT_SUCCESS:
        return { ...state, PurchaseData: action.payload };
    case actionType.PURCHASE_INSERT_ERROR:
        return { ...state, PurchaseData: "" };


      case actionType.SALE_INSERT:
        return { ...state, SaleData: "" };
    case actionType.SALE_INSERT_SUCCESS:
        return { ...state, SaleData: action.payload };
    case actionType.SALE_INSERT_ERROR:
        return { ...state, SaleData: "" };


        case actionType.PRINTPURCHASE:
          return { ...state, PrintPurchaseData: "" };
      case actionType.PRINT_PURCHASE_SUCCESS:
          return { ...state, PrintPurchaseData: action.payload.Result };
      case actionType.PRINT_PURCHASE_ERROR:
          return { ...state, PrintPurchaseData: "" };
  

    

    default:
      return state;
  }
};
export default SaleReducer;