import {takeEvery,put,call,all, take} from "redux-saga/effects"
import { actionType ,LoginSuccess,LoginFail,OTPSuccess,OTPFail} from "./action"
import AuthenticationServie from '../../Service/AuthenticationServie'
import { startLoader,stopLoader } from "../../Redux/Common/Loader/action";


function* Login({ payload }) {
    try {
      yield put(startLoader());
      const data = yield call(AuthenticationServie.Login, payload);
      yield put(LoginSuccess(data));
    } catch (error) {
      yield put(LoginFail(error));
      console.log(error);
    } finally {
      yield put(stopLoader());
    }
  }
  function* OTP({ payload }) {
    try {
      yield put(startLoader());
      const data = yield call(AuthenticationServie.OTP, payload);
      yield put(OTPSuccess(data));
    } catch (error) {
      yield put(OTPFail(error));
      console.log(error);
    } finally {
      yield put(stopLoader());
    }
  }

export default function* rootSaga(){
    yield all ([takeEvery(actionType.LOGIN,Login)])
    yield all ([takeEvery(actionType.OTP,OTP)])

}