import { takeEvery, put, call, all, take } from "redux-saga/effects";
import { startLoader,stopLoader } from "../../Redux/Common/Loader/action";
import PartyService from "../../Service/PartyService";
import {
  actionType,
  PartySuccess,
  PartyFail,
  GetPartySuccess,
  GetPartyFail,
  GroupSuccess,
  GroupFail,
  ADDPARTYSuccess,
  ADDPARTYFail,
  StateSuccess,
  StateFail,
  GetAllPartySuccess,
  GetAllPartyFail

  
} from "./action";

function* Party({ payload }) {
  try {
      yield put(startLoader());
    const data = yield call(PartyService.Party, payload);
    yield put(PartySuccess(data));
  } catch (error) {
    yield put(PartyFail(error));
    console.log(error);
  } finally {
      yield put(stopLoader());
  }
}

function* GetParty({ payload }) {
  try {
      yield put(startLoader());
    const data = yield call(PartyService.GetParty, payload);
    yield put(GetPartySuccess(data));
  } catch (error) {
    yield put(GetPartyFail(error));
    console.log(error);
  } finally {
      yield put(stopLoader());
  }
}



function* ADDPARTY({ payload }) {
  try {
      yield put(startLoader());
    const data = yield call(PartyService.ADDPARTY, payload);
    yield put(ADDPARTYSuccess(data));
  } catch (error) {
    yield put(ADDPARTYFail(error));
    console.log(error);
  } finally {
      yield put(stopLoader());
  }
}

function* State({ payload }) {
  try {
      yield put(startLoader());
    const data = yield call(PartyService.State, payload);
    yield put(StateSuccess(data));
  } catch (error) {
    yield put(StateFail(error));
    console.log(error);
  } finally {
      yield put(stopLoader());
  }
}

function* GetAllParty({ payload }) {
  try {
      yield put(startLoader());
    const data = yield call(PartyService.GetAllParty, payload);
    yield put(GetAllPartySuccess(data));
  } catch (error) {
    yield put(GetAllPartyFail(error));
    console.log(error);
  } finally {
      yield put(stopLoader());
  }
}
export default function* rootSaga() {
  yield all([takeEvery(actionType.PARTY, Party)]);
  yield all([takeEvery(actionType.GETPARTY, GetParty)]);
  yield all([takeEvery(actionType.ADDPARTY, ADDPARTY)]);
  yield all([takeEvery(actionType.STATE, State)]);
  yield all([takeEvery(actionType.GETALLPARTY, GetAllParty)]);
}
