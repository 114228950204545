import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { OTP } from "../../Redux/Authentication/action";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";



const Otp = () =>{
    const dispatch = useDispatch();
  const [Otp, setOtp] = useState("");
  const navigate = useNavigate();

  const OtpVlaue = useSelector((state) => state.Authentication.OTPData);
  console.log("OtpVlaue", OtpVlaue.StatusCode);

useEffect(() => {
  console.log("OtpVlaue",OtpVlaue);
  if (OtpVlaue && OtpVlaue.StatusCode== 200){
    navigate("/admin");

  }
  else if (OtpVlaue && OtpVlaue.StatusCode === 404) {
    toast.error("Invalid OTP");
  }
}, [OtpVlaue])


  const HandelSave = () => {
  
    dispatch(
        OTP({
            Otp: Otp

        })
      );
  };
  return (
    <div className="relative z-50 flex min-h-screen flex-col justify-center  overflow-hidden py-12">
      <div className="relative mx-auto w-full max-w-lg rounded-2xl bg-white px-6 pt-10 pb-9 shadow-xl">
        <div className="mx-auto flex w-full max-w-md flex-col space-y-16">
          <div className="flex flex-col items-center justify-center space-y-2 text-center">
            <div className="text-3xl font-semibold">
              <p>OTP Verification</p>
            </div>
            <div className="flex flex-row text-sm font-medium text-gray-400">
              <p>We have sent a code to your Registered Mobile Number</p>
            </div>
          </div>

          <div>
            <div className="flex flex-col space-y-16">
              <div className="mx-auto flex w-full max-w-xs flex-row items-center justify-between">
                <input
                  type="text"
                  name=""
                  id=""
                  value={Otp}
                  onChange={(e) => {
                    setOtp(e.target.value);
                  }}
                  className="h-12 w-full rounded-lg border border-gray-300 bg-white px-4 py-2 text-gray-800 placeholder-gray-500 shadow-sm focus:border-blue-500 focus:outline-none focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  placeholder="Enter OTP here..."
                />
              </div>

              <div className="flex flex-col space-y-5">
                <div>
                  <button
                    onClick={HandelSave}
                    className="flex w-full flex-row items-center justify-center rounded-xl border border-none bg-blue-700 py-5 text-center text-sm text-white shadow-sm outline-none"
                  >
                    Verify Account
                  </button>
                </div>

                {/* <div className="flex flex-row items-center justify-center space-x-1 text-center text-sm font-medium text-gray-500">
                    <p>Didn't receive code?</p>{" "}
                    <a
                      className="flex flex-row items-center text-blue-600"
                      href="http://"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Resend
                    </a>
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Otp;
