import Card from 'components/card';
import React, { useEffect, useState } from 'react'
import { BsPlusCircleFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { GetParty } from '../../../../Redux/Party/action';
import Pricegroupmodal from './Pricegroupmodal';
import { PriceGroupDeleteMaster, PriceGroupListMaster, PriceGroupListMasterError } from '../../../../Redux/Master/action';
import toast from 'react-hot-toast';
import { MdDeleteForever, MdEditNote } from 'react-icons/md';

function PriceGroup() {



    const [open, setOpen] = useState(false);
    const [ModalOpen, setModalOpen] = useState(false)
    const [Editvalue, setEditvalue] = useState();
     const dispatch =useDispatch()
    const TempleId = JSON.parse(localStorage.getItem('Sopanam_TempleId'));
    const addpricegroup =useSelector((state)=>state.Master.pricegroupadd)
    const listpricegroup =useSelector((state)=>state.Master.pricegrouplist)
    const deletepricegroup =useSelector((state)=>state.Master.pricegroupdelete)
useEffect(()=>{
    dispatch(PriceGroupListMaster({TempleId:TempleId}))
},[addpricegroup,deletepricegroup])

console.log("TempleId",TempleId);

useEffect(() => {
  if (addpricegroup.Id > 0) {
    toast.success("Price Group Succuss");
  }
  if (deletepricegroup.Id > 0) {
    toast.success("Price Group Deleted");
  }
}, [addpricegroup,deletepricegroup]);


  return (
<div className='mt-5'>
{
    ModalOpen ?(
        <Pricegroupmodal setModalOpen={setModalOpen} ModalOpen={ModalOpen}  Editvalue={Editvalue}/>

    ):""
}
    <Card extra={"w-full h-full p-4"}>
      <div className="relative flex items-center justify-between ">
        <div className="pb-5 text-xl font-bold text-navy-700 dark:text-white">
         PRICE GROUP
        </div>
        <div>
          {" "}
          <button
            data-modal-target="crud-modal"
            data-modal-toggle="crud-modal"
            onClick={() =>{
              setEditvalue("")
              setOpen(!open)
            }
            }
            open={open}
            className={`flex items-center text-xl hover:cursor-pointer ${
              // transparent
              // "bg-none text-white hover:bg-none active:bg-none"
              "mb-2 mr-5 bg-lightPrimary p-2 text-brand-green hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"
            } linear justify-center rounded-lg font-bold transition duration-200`}
          >
            <BsPlusCircleFill onClick={()=>{setModalOpen(true)}} className="h-6 w-6" />
          </button>
        </div>
      </div>

      <div className="h-full md:h-[420px] md:overflow-y-auto ">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-left text-sm text-gray-500 rtl:text-right dark:text-gray-400">
            <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Sl No
                </th>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Code
                </th>
                <th scope="col" className="px-6 py-3">
                      Action
                    </th>
              </tr>
            </thead>
            <tbody>
            {listpricegroup.length?listpricegroup?.map((item, i) => (
          <tr key={i}>
            <td className="px-6 py-4 ">{ i + 1}</td>
            <td className="px-6 py-4 ">{item.Name}</td>
            <td className="px-6 py-4 ">{item.Code}</td>
            <td className="px-6 py-4 whitespace-nowrap">
                       <button
                            type="button"
                            className="text-green-600  mr-5 "
                            onClick={() =>{
                              setEditvalue(item)
                              setModalOpen(true)
                            }
                            }
                          >
                            <MdEditNote
                              className="mt-1"
                              style={{ fontSize: "1.5rem" }}
                            
                              />
                          </button>
              <button
              type="button"
                className="text-red-600 hover:text-red-900"
                onClick={() => {
                 dispatch(PriceGroupDeleteMaster({
                  PgID:item.Id,
                  TempleId:TempleId
                 }))
                }}
              >
                <MdDeleteForever className="mt-1" style={{ fontSize: "1.5rem" }} />
              </button>
             

            </td>
           
          </tr>
        )):<div className="items-center"> No Data Found</div>}
            </tbody>
          </table>
        </div>
      </div>
    </Card>
    </div>
  )
}

export default PriceGroup