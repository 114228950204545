import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PrintPurchase } from "../../Redux/Sale/action";
import { ToWords } from "to-words";

const PurchasePrint = React.forwardRef((props, ref) => {
  const TempleID = JSON.parse(localStorage.getItem("Sopanam_TempleId"));

  const PurchaseDatavalue = useSelector((state) => state.Sale.PurchaseData);
  const printdata = useSelector((state) => state.Sale.PrintPurchaseData);

  const toWords = new ToWords({
    localeCode: "en-IN",
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false,
      currencyOptions: {
        // can be used to override defaults for the selected locale
        name: "Rupee",
        plural: "Rupees",
        symbol: "₹",
        fractionalUnit: {
          name: "Paisa",
          plural: "Paise",
          symbol: "",
        },
      },
    },
  });

  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    // Calculate total amount when printdata changes
    const total =
      Array.isArray(printdata) &&
      printdata.reduce((accumulator, currentItem) => {
        return accumulator + parseFloat(currentItem.Amount);
      }, 0);
    setTotalAmount(total);
  }, [printdata]);

  useEffect(() => {
    console.log("tOtal", totalAmount);
  }, [totalAmount]);

  // Output: "nineteen point two"

  let NumberNeedToConvert = totalAmount
    ? totalAmount.toFixed(2) - (printdata[0]?.Discount || 0)
    : 0;

  console.log("PurchaseDatavalue", PurchaseDatavalue);
  console.log("Total Amount:", totalAmount);
  console.log("Discount:", printdata[0]?.Discount);

  function formatDate(inputDate) {
    // Create a new Date object from the input string
    const date = new Date(inputDate);

    // Extract day, month, and year from the date object
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    // Concatenate the formatted date parts
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }
  return (
    <div style={{ display: "none" }}>
      <div className="m-5   border  border-black " ref={ref}>
        <div className="flex justify-center p-5 text-2xl">Sopanam</div>

        <div className="flex justify-center">
           {/* <div>
  <p className="justify-center flex">  Established in 2011, InIT has served more than 100 clients that include many of the major hospitals and laboratories in the country and abroad.</p>
          </div>  */}
        </div>

        <div className="mt-2 flex justify-center border-y border-y-black p-1 text-lg">
          Purchase Report
        </div>
        <div className="flex ">
          <div className="mt-2 w-1/3  ">
            <div className="ml-2 block">
              {" "}
              Party Name:{" "}
              <span className="ml-2">{printdata[0]?.PartyName}</span>{" "}
            </div>
            <div className="ml-2 block">
              {" "}
              Address: <span className="ml-2">
                {printdata[0]?.Address}
              </span>{" "}
            </div>
            {/* <div className="ml-2 block">
              {" "}
              Party <span className="ml-2">{printdata[0]?.PartyName}</span>{" "}
            </div> */}

            <div className="ml-2 block">
              {" "}
              <div className="flex">
                <div>PhoneNumber:    </div>
              </div>
            </div>
          </div>
          <div className="ml-2 mr-2 block w-full ">
            {" "}
            <div className="flex justify-end">
              Invoice Number:{" "}
              <span className="ml-2">{printdata[0]?.BillNumber}</span>{" "}
            </div>
            <div className="flex justify-end">
              Invoice Date:{" "}
              <span className="ml-2">{formatDate(printdata[0]?.BillDate)}</span>{" "}
            </div>
          </div>
        </div>

        <div class="mt-2 flex flex-col">
          <div class="overflow-x-auto sm:mx-0.5 lg:mx-0.5">
            <div class="inline-block min-w-full py-2 sm:px-2 lg:px-8">
              <div class="overflow-hidden">
                <table class="min-w-full">
                  <thead class="border-b bg-white">
                    <tr>
                      <th
                        scope="col"
                        class="px-2 py-2 text-left text-sm font-medium text-gray-900"
                      >
                        Item Name
                      </th>
                      <th
                        scope="col"
                        class="px-2 py-2 text-left text-sm font-medium text-gray-900"
                      >
                        Quantity
                      </th>
                      <th
                        scope="col"
                        class="px-2 py-2 text-left text-sm font-medium text-gray-900"
                      >
                        Rate
                      </th>
                      <th
                        scope="col"
                        class="px-2 py-2 text-left text-sm font-medium text-gray-900"
                      >
                        Amount
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {Array.isArray(printdata) &&
                      printdata.map((row, index) => (
                        <tr
                          key={index}
                          className={
                            index % 2 === 0 ? "bg-gray-100" : "bg-white"
                          }
                        >
                          <td>{row.item}</td>
                          <td>{row.Quantity}</td>
                          <td>{row.rate}</td>
                          <td>{row.BillAmount}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="flex  bg-gray-300 ">
          <div className="w-1/2 ">
            <div className="mt-2 flex justify-center">
              Total Amount in Words
            </div>
            <div className="flex justify-center p-5 text-lg">
              <b>{toWords.convert(NumberNeedToConvert)}</b>
            </div>
          </div>

          <div className=" w-1/2">
            <div className="mr-5 text-end">
              Amount: <b>{totalAmount && totalAmount.toFixed(2)}Rs</b>{" "}
            </div>
            <div className="mr-5 text-end">
              Discount: <b>{printdata[0]?.Discount}Rs</b>
            </div>
            <div className="mr-5 mt-3 text-end">
              <b className="text-2xl">
                {" "}
                Total:{" "}
                <b>
                  {totalAmount &&
                    totalAmount?.toFixed(2) - printdata[0]?.Discount}
                </b>
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PurchasePrint;
