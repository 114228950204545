import {takeEvery,put,call,all} from "redux-saga/effects"
import { actionType ,AdditemSucces,AdditemError,ListitemSucces,
ListitemError,
DeleteitemSucces,
DeleteitemError} from "./action"
import ItemService from "../../Service/ItemService"
import { startLoader,stopLoader } from "../../Redux/Common/Loader/action";



function* Additem({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(ItemService.Additem, payload);
    yield put(AdditemSucces(data));
  } catch (error) {
    yield put(AdditemError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* Listitem({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(ItemService.Listitem, payload);
    yield put(ListitemSucces(data));
  } catch (error) {
    yield put(ListitemError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* Deleteitem({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(ItemService.Deleteitem, payload);
    yield put(DeleteitemSucces(data));
  } catch (error) {
    yield put(DeleteitemError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}


export default function* rootsaga(){
  yield all([takeEvery(actionType.ADD_ITEM, Additem)]);
  yield all([takeEvery(actionType.LIST_ITEM, Listitem)]);
  yield all([takeEvery(actionType.DELETE_ITEM, Deleteitem)]);
  }
