import axios from "axios";
import { apiurl } from "configs/config";
import ApiClient from "./ApiClient";

class AuthenticationServie {
  constructor(callback) {
    this.callback = callback;
  }

  async Login(payload) {
    const response = await axios.post(
        `${apiurl}/api/Login/CheckUSerValidity?EmailId=${payload.EmailId}&Password=${payload.Password}`,
        
    ).then((response) => {
      
        return response.data;
        
    }).catch((error) => ({ error: JSON.stringify(error) }));
    return response;
}

async OTP(payload) {
  const response = await axios.post(
      `${apiurl}/api/Login/ValidateUserOtp?Otp=${payload.Otp}`
      
  ).then((response) => {
    console.log("responseOTP",response)
    
    localStorage.setItem('ApiToken_SopanamSTORE', JSON.stringify(response.data.Token))
    localStorage.setItem('Sopanam_EditRights', JSON.stringify(response.data.Result.IsEditRightsForSopanamWeb))
    localStorage.setItem('Sopanam_UserId', JSON.stringify(response.data.Result.Id))
    localStorage.setItem('Sopanam_TempleId', JSON.stringify(response.data.Result.TempleId))
      return response.data;
      
  }).catch((error) => ({ error: JSON.stringify(error) }));
  return response;
}

}

export default new AuthenticationServie