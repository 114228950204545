import { combineReducers } from "redux";
import Authentication from '../Authentication/reducer'
import  Items  from "../Items/reducer";
import Party from "../Party/reducer";
import Sale from '../Sale/reducer'
import Master from '../Master/reducer'
import Report from '../Report/reducer'
import Common from '../Common/commonReducer'

const rootReducer = combineReducers({
    Authentication,
    Items,
    Party,
    Sale,
    Master,
    Report,
    Common
    
})
export default rootReducer