import React, { useState } from "react";
import * as Dialog from "@radix-ui/react-dialog";
import { Cross2Icon } from "@radix-ui/react-icons";
import Checkbox from '../../../../components/checkbox'
import { useDispatch, useSelector } from "react-redux";
import { Additem } from "../../../../Redux/Items/action";
import { useEffect } from "react";
import { unitsmeasure } from "../../../../Redux/Master/action";

const DialogDemo = ({ open, setOpen,Editvalue }) => {
  const dispatch = useDispatch()
  const [isTaxInclusive, setisTaxInclusive] = useState(false);
  const [IsRowMaterial, setIsRowMaterial] = useState(false);
  const [name, setName] = useState();
  const [code, setCode] = useState();
  const [price, setPrice] = useState();
  const [Gst, setGst] = useState();
  const [openingstock, setopeningstock] = useState();
  const [openingstockValue, setopeningstockValue] = useState();
  const [reorderlevel, setreorderlevel] = useState();
  const [unit, setunit] = useState();
  const TempleId = JSON.parse(localStorage.getItem('Sopanam_TempleId'));
  const UserId = JSON.parse(localStorage.getItem('Sopanam_UserId'));
  const unitlist = useSelector((state) => state.Master.unitvalue);

console.log("Editvalue",Editvalue);

useEffect(()=>{
if(Editvalue){
  setName(Editvalue.Name);
  setCode(Editvalue.Code);
  setPrice(Editvalue.Rate);
  setGst(Editvalue.Tax);
  setunit(Editvalue.UnitId);
  setopeningstock(Editvalue.OpeningStock)
  setreorderlevel(Editvalue.ItemReOrderLevel)
  setopeningstockValue(Editvalue.OpeningStockValue)
  setisTaxInclusive(Editvalue.TaxType==1?true:false);
  setIsRowMaterial(Editvalue.IsRowMaterial==1?true:false);

}
},[Editvalue])

useEffect(()=>{
  dispatch(unitsmeasure())
},[])

  const HandlesaveChanges = () => {

if(Editvalue){
    dispatch(Additem({
      Id: Editvalue.Id,
      Name: name,
      Code: code,
      Barcode: "",
      UserId: UserId,
      Rate: price,
      HSNCode: "",
      Tax: Gst,
      Cess: 0,
      Unit: "",
      UOM:unit,
      OpeningStock: openingstock,
      OpeningStockValue: openingstockValue,
      ReOrderLevel:reorderlevel,
      TaxType: isTaxInclusive?1:0,
      IsRowMaterial: IsRowMaterial?1:0,
      TempleId: TempleId,
      ItemRates: [
        {
          PriceGroupId: 1,
          Rate: price
        }
      ]
    }))
  }
  else{
    dispatch(Additem({
      Id: 0,
      Name: name,
      Code: code,
      Barcode: "",
      UserId: UserId,
      Rate: price,
      HSNCode: "",
      Tax: Gst,
      Cess: 0,
      Unit: "",
      UOM:unit,
      OpeningStock: openingstock,
      OpeningStockValue: openingstockValue,
      ReOrderLevel:reorderlevel,
      TaxType: isTaxInclusive?1:0,
      IsRowMaterial: IsRowMaterial?1:0,
      TempleId: TempleId,
      ItemRates: [
        {
          PriceGroupId: 1,
          Rate: price
        }
      ]
    }))
  }
  };



  return(
    <Dialog.Root open={open} onOpenChange={setOpen}>
    <Dialog.Portal>
      <Dialog.Overlay className="data-[state=open]:animate-overlayShow fixed inset-0 bg-blackA6 " />
      <Dialog.Content className="data-[state=open]:animate-contentShow md: md: fixed top-[50%] left-[50%] z-50 max-h-[85vh] w-full max-w-[600px] translate-x-[-50%] translate-y-[-50%] overflow-y-scroll rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
        <Dialog.Title className="m-0 mb-4 flex  text-[17px] font-medium text-mauve12">
           Insert Item
        </Dialog.Title>

        <div className="mb-[15px] flex-1 items-center gap-5">
      <label
        className="w-[90px] text-right text-[15px] text-mauve12"
        htmlFor="name"
      >
        Name
      </label>
      <input
        className="inline-flex h-[35px] w-full flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none text-mauve12 shadow-[0_0_0_1px] shadow-mauve12 outline-none focus:shadow-[0_0_0_2px] focus:shadow-violet8"
        id="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
    </div>
        <div className=" flex justify-around">
          <div className="mb-[15px] flex-1 items-center gap-5">
            <label
              className="w-[90px] flex-1 text-right text-[15px] text-mauve12"
              htmlFor="code"
            >
              Code
            </label>
            <input
              className="inline-flex h-[35px] w-[250px] flex-1 flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none text-mauve12 shadow-[0_0_0_1px] shadow-mauve12 outline-none focus:shadow-[0_0_0_2px] focus:shadow-violet8"
              id="code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
          <div  className="mb-[15px] flex-1 items-center gap-5">
          <label
            className="w-[90px] text-right text-[15px] text-mauve12"
            htmlFor="price"
          >
            Price
          </label>
          <input
          type="number"
            className="inline-flex h-[35px] w-full flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none text-mauve12 shadow-[0_0_0_1px] shadow-mauve12 outline-none focus:shadow-[0_0_0_2px] focus:shadow-violet8"
            id="price"
            onWheel={(e) => e.target.blur()}
            value={price}
            onChange={(e) => setPrice(e.target.value)}
           
          />
          </div>
        </div>
        <div className=" flex justify-around">
          <div className="mb-[15px] flex-1 items-center gap-5">
            <label
              className="w-[90px] flex-1 text-right text-[15px] text-mauve12"
              htmlFor="gst"
            >
              Opening Stock
            </label>
            <input
            type="number"
              className="inline-flex h-[35px] w-[250px] flex-1 flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none text-mauve12 shadow-[0_0_0_1px] shadow-mauve12 outline-none focus:shadow-[0_0_0_2px] focus:shadow-violet8"
              id="gst"
              value={openingstock}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => setopeningstock(e.target.value)}
            />
          </div>
          <div  className="mb-[15px] flex-1 items-center gap-5">
          <label
            className="w-[90px] text-right text-[15px] text-mauve12"
            htmlFor="price"
          >
            Opening Stock Value
          </label>
          <input
              className="inline-flex h-[35px] w-[250px] flex-1 flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none text-mauve12 shadow-[0_0_0_1px] shadow-mauve12 outline-none focus:shadow-[0_0_0_2px] focus:shadow-violet8"
              id="gst"
              type='number'
              value={openingstockValue}
              onWheel={(e) => e.target.blur()}
              onChange={(e) => setopeningstockValue(e.target.value)}
            />
          </div>
        </div>
        <div className=" flex justify-around">
          <div className="mb-[15px] flex-1 items-center gap-5">
            <label
              className="w-[90px] flex-1 text-right text-[15px] text-mauve12"
              htmlFor="gst"
            >
              Reorder Level
            </label>
            <input
              className="inline-flex h-[35px] w-[250px] flex-1 flex-1 items-center justify-center rounded-[4px] px-[10px] text-[15px] leading-none text-mauve12 shadow-[0_0_0_1px] shadow-mauve12 outline-none focus:shadow-[0_0_0_2px] focus:shadow-violet8"
              id="gst"
              value={reorderlevel}
              onChange={(e) => setreorderlevel(e.target.value)}
            />
          </div>
          <div  className="mb-[15px] flex-1 items-center gap-5">
          <label
            className="w-[90px] text-right text-[15px] text-mauve12"
            htmlFor="price"
          >
            Unit
          </label>
          <select
                className="block w-full rounded-lg border border-green-500 border-gray-700 bg-gray-50 p-2.5 text-sm text-gray-900 focus:ring-blue-500 dark:border dark:border-gray-600 dark:bg-gray-700 dark:text-white dark:placeholder-gray-400 dark:focus:ring-blue-500"
                id="party"
                value={unit}
                onChange={(e) => setunit(e.target.value)}
              >
                {/* Default "Select Party" option */}
                <option value="">Select Unit</option>

                {/* Check if PartyData is an array and not empty before mapping */}
                {Array.isArray(unitlist) &&
                  unitlist.length > 0 &&
                  unitlist.map((unit) => (
                    <option key={unit.Id} value={unit.Id}>
                      {unit.Name}
                    </option>
                  ))}
              </select>
          </div>
        </div>
      
        <div className="flex mt-3 mb-3">
  {/* <div className="flex-1 flex items-center gap-2">
    <Checkbox checked={isTaxInclusive} onChange={() => setisTaxInclusive(!isTaxInclusive)}/>
    <p className="text-sm font-bold text-navy-700 dark:text-white">
      Tax Inclusive
    </p>
  </div> */}
  <div className="flex-1 flex items-center gap-2">
    <Checkbox checked={IsRowMaterial} onChange={()=>{setIsRowMaterial(!IsRowMaterial)}} />
    <p className="text-sm font-bold text-navy-700 dark:text-white">
      Is Row Material
    </p>
  </div>
 </div>
 <div className="mt-[25px] flex justify-end">
          <Dialog.Close asChild>
            <button
      className="inline-flex h-[35px] items-center justify-center rounded-[4px] bg-green4 px-[15px] font-medium leading-none text-green11 hover:bg-green5 focus:shadow-[0_0_0_2px] focus:shadow-green7 focus:outline-none"
      onClick={HandlesaveChanges}
    >
      Save Item
    </button>
          </Dialog.Close>
        </div>
       
        <Dialog.Close asChild>
          <button
            className="absolute top-[10px] right-[10px] inline-flex h-[25px] w-[25px] appearance-none items-center justify-center rounded-full text-mauve12 hover:bg-violet4 focus:shadow-[0_0_0_2px] focus:shadow-mauve12 focus:outline-none"
            aria-label="Close"
          >
            <Cross2Icon />
          </button>
        </Dialog.Close>
      </Dialog.Content>
    </Dialog.Portal>
  </Dialog.Root>
  )
};
  


export default DialogDemo;
