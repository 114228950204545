import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import {store} from './Redux/StoreConfig/Store'
import App from "./App";
import { Provider } from "react-redux";
import  { Toaster } from 'react-hot-toast';
import PurchasePrint from "views/Print/PurchasePrint";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <Toaster/>

  <BrowserRouter>


    <App />
    {/* <PurchasePrint/> */}
  </BrowserRouter>
  </Provider>
);
