import {takeEvery,put,call,all} from "redux-saga/effects"
import { actionType ,PartyTypeAddMasterSucces,PartyTypeAddMasterError,PriceGroupAddMasterSucces,
    PriceGroupAddMasterError,PriceGroupListMasterSucces,PriceGroupListMasterError,
    unitsmeasureSucces,unitsmeasureError, TotalViewSucces, TotalViewError, PartyTypeDeleteMasterSucces, PartyTypeDeleteMasterError, PriceGroupDeleteMasterSucces, PriceGroupDeleteMasterError} from "./action"
import MasterService from "../../Service/MasterService"
import { startLoader,stopLoader } from "../../Redux/Common/Loader/action";


function* PartyTypeAddMaster({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(MasterService.PartyTypeAddMaster, payload);
    yield put(PartyTypeAddMasterSucces(data));
  } catch (error) {
    yield put(PartyTypeAddMasterError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}


function* PartyTypeDeleteMaster({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(MasterService.PartyTypeDeleteMaster, payload);
    yield put(PartyTypeDeleteMasterSucces(data));
  } catch (error) {
    yield put(PartyTypeDeleteMasterError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* PriceGroupAddMaster({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(MasterService.PriceGroupAddMaster, payload);
    yield put(PriceGroupAddMasterSucces(data));
  } catch (error) {
    yield put(PriceGroupAddMasterError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* PriceGroupDeleteMaster({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(MasterService.PriceGroupDeleteMaster, payload);
    yield put(PriceGroupDeleteMasterSucces(data));
  } catch (error) {
    yield put(PriceGroupDeleteMasterError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* PriceGroupListMaster({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(MasterService.PriceGroupListMaster, payload);
    yield put(PriceGroupListMasterSucces(data));
  } catch (error) {
    yield put(PriceGroupListMasterError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* unitsmeasure({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(MasterService.unitsmeasure, payload);
    yield put(unitsmeasureSucces(data));
  } catch (error) {
    yield put(unitsmeasureError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* TotalView({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(MasterService.TotalView, payload);
    yield put(TotalViewSucces(data));
  } catch (error) {
    yield put(TotalViewError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}




export default function* rootsaga(){
  yield all([takeEvery(actionType.MASTER_PARTY_TYPE_ADD, PartyTypeAddMaster)]);
  yield all([takeEvery(actionType.MASTER_PARTY_TYPE_DELETE, PartyTypeDeleteMaster)]);
  yield all([takeEvery(actionType.MASTER_PRICE_GROUP_ADD, PriceGroupAddMaster)]);
  yield all([takeEvery(actionType.MASTER_PRICE_GROUP_DELETE, PriceGroupDeleteMaster)]);
  yield all([takeEvery(actionType.MASTER_PRICE_GROUP_LIST, PriceGroupListMaster)]);
  yield all([takeEvery(actionType.UNITS, unitsmeasure)]);
  yield all([takeEvery(actionType.VIEW_TOTAL, TotalView)]);
  }
