import axios from "axios";
import { apiurl } from "configs/config";
import ApiClient from "./ApiClient";

class SaleService {
  constructor(callback) {
    this.callback = callback;
  }

  async ItemFastBilling(payload) {
    const response = await axios.get(
      `${apiurl}/api/Item/GetAllitemsForFastBilling?TempleId=${payload.TempleId}`,
      payload
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
    return response;
  }

  async PurchaseInsert(payload) {
    const response = await axios.post(
      `${apiurl}/api/Transaction/InsertTransaction`,
      payload
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
    return response;
  }

  async SaleInsert(payload) {
    const response = await axios.post(
      `${apiurl}/api/Transaction/InsertTransaction`,
      payload
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
    return response;
  }

  async PrintPurchase(payload) {
    const response = await axios.get(
      `${apiurl}/api/Transaction/GetTransactionWithId?TId=${payload.TID}&TempleId=${payload.TempleId}`,
      payload
    )
      .then((response) => {
        return response.data;
      })
      .catch((error) => ({ error: JSON.stringify(error) }));
    return response;
  }
}
export default new SaleService();
