export const actionType = {
    LOGIN:"LOGIN",
    LOGIN_SUCCESS:"LOGIN_SUCCESS",
    LOGIN_FAIL:"LOGIN_FAIL",


    OTP:"OTP",
    OTP_SUCCESS:"OTP_SUCCESS",
    OTP_FAIL:"OTP_FAIL",

    

}
export const Login = (payload)=>{
    return {
        type:actionType.LOGIN,
        payload
    }
}

export const LoginSuccess = (payload)=>{
    return {
        type:actionType.LOGIN_SUCCESS,
        payload
    }
}

export const LoginFail = (payload)=>{
    return{
        type:actionType.LOGIN_FAIL,
        payload
    }
}


export const OTP = (payload)=>{
    return {
        type:actionType.OTP,
        payload
    }
}

export const OTPSuccess = (payload)=>{
    return {
        type:actionType.OTP_SUCCESS,
        payload
    }
}

export const OTPFail = (payload)=>{
    return{
        type:actionType.OTP_FAIL,
        payload
    }
}