import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Listitem } from "../../../Redux/Items/action";


function PurchaseItemModal(props) {
  
    const ItemData = useSelector((state) => state.Items.ListItemData);
    const TempleID = JSON.parse(localStorage.getItem("Sopanam_TempleId"));

    const [checkedItems, setCheckedItems] = useState([]);
    useEffect(() => {
     console.log("checkedItems",checkedItems);
    }, [checkedItems])
    
    useEffect(() => {
    console.log("props.ShowModal",props.ShowModal)
    
     }, [props.ShowModal])
  

    const handleCheckboxChange = (itemId) => {
        const isChecked = props.ChoosedItems.map(el => el.Id).includes(itemId);
        console.log("choosedItems => ", props.ChoosedItems, itemId, isChecked)
        
        if (isChecked) {
          // If the item is already checked, remove it from the array
          props.setChoosedItems(prevItems => prevItems.filter(item => item !== itemId));
        } else {
          // If the item is not checked, add it to the array
          props.setChoosedItems(prevItems => [...prevItems, {...itemId, Quantity: 1}]);
        }
      };
      

    const Dispatch = useDispatch();
    useEffect(() => {
        
        Dispatch(
          Listitem({
            TempleId: TempleID,
          })
        );
      }, []);
    
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
      <div id="default-modal" tabIndex="-1" aria-hidden="true" className="relative p-4 w-full max-w-2xl">
        <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
            <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Item List</h3>
            <button onClick={()=>{props.setShowModal(!props.ShowModal)}} type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-hide="default-modal">
              <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-4 md:p-5 space-y-4">
        {Array.isArray(ItemData) && ItemData.map((item, index) => (
          <label key={index} className={`pb-4 flex items-center ${index !== ItemData.length - 1 ? 'border-b border-gray-200' : ''}`}>
            <input
              type="checkbox"
              name={item.Name}
              checked={props.ChoosedItems.map(el => el.Id).includes(item.Id)}
              onChange={() => handleCheckboxChange(item)}
            />
            <span className="ml-2">{item.Name}</span> 
          </label>
        ))}
      </div>


          <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
            <button data-modal-hide="default-modal" type="button" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"  onClick={()=>{props.setShowModal(!props.ShowModal)}}>Save</button>
            {/* <button data-modal-hide="default-modal" type="button" className="py-2.5 px-5 ms-3 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Decline</button> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PurchaseItemModal;
