import { actionType } from "./action";
export const InitialState = {
  AddItemData: "",
  ListItemData: "",
  deleteitemdata:''
};

const ItemReducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionType.ADD_ITEM:
      return { ...state, AddItemData: "" };
  case actionType.ADD_ITEM_SUCCESS:
      return { ...state, AddItemData: action.payload.Result.Result };
  case actionType.ADD_ITEM_ERROR:
      return { ...state, AddItemData: "error" };

    case actionType.LIST_ITEM:
      return { ...state, ListItemData: "" };
  case actionType.LIST_ITEM_SUCCESS:
      return { ...state, ListItemData: action.payload };
  case actionType.LIST_ITEM_ERROR:
      return { ...state, ListItemData: "error" };


    case actionType.DELETE_ITEM:
      return { ...state, deleteitemdata: "" };
  case actionType.DELETE_ITEM_SUCCESS:
      return { ...state, deleteitemdata: action.payload };
  case actionType.DELETE_ITEM_ERROR:
      return { ...state, deleteitemdata: "error" };

    default:
      return state;
  }
};
export default ItemReducer;