import {takeEvery,put,call,all} from "redux-saga/effects"
import { actionType ,StockReportSucces,StockReportError, RecipesReportSucces, RecipesReportError, sectionmapperSucces, sectionmapperError, TransactionReportSucces, TransactionReportError} from "./action"
import ReportService from "../../Service/ReportService";
import { startLoader,stopLoader } from "../../Redux/Common/Loader/action";


function* StockReport({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(ReportService.StockReport, payload);
    yield put(StockReportSucces(data));
  } catch (error) {
    yield put(StockReportError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}


function* RecipesReport({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(ReportService.RecipesReport, payload);
    yield put(RecipesReportSucces(data));
  } catch (error) {
    yield put(RecipesReportError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* sectionmapper({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(ReportService.sectionmapper, payload);
    yield put(sectionmapperSucces(data));
  } catch (error) {
    yield put(sectionmapperError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* TransactionReport({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(ReportService.TransactionReport, payload);
    yield put(TransactionReportSucces(data));
  } catch (error) {
    yield put(TransactionReportError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}




export default function* rootsaga(){
  yield all([takeEvery(actionType.STOCK_REPORT, StockReport)]);
  yield all([takeEvery(actionType.RECIPES_REPORT, RecipesReport)]);
  yield all([takeEvery(actionType.SECTION_MAPPER, sectionmapper)]);
  yield all([takeEvery(actionType.TRANSACTION_DETAILS, TransactionReport)]);
}
