export const actionType = {
    ADD_ITEM:"ADD_ITEM",
    ADD_ITEM_SUCCESS:"ADD_ITEM_SUCCESS",
    ADD_ITEM_ERROR:"ADD_ITEM_ERROR",

    LIST_ITEM:"LIST_ITEM",
    LIST_ITEM_SUCCESS:"LIST_ITEM_SUCCESS",
    LIST_ITEM_ERROR:"LIST_ITEM_ERROR",

    DELETE_ITEM:"DELETE_ITEM",
    DELETE_ITEM_SUCCESS:"DELETE_ITEM_SUCCESS",
    DELETE_ITEM_ERROR:"DELETE_ITEM_ERROR",


    
}

export const Additem = (payload) => {
    return {
      type: actionType.ADD_ITEM,
      payload,
    };
  };
  export const AdditemSucces = (payload) => {
    return {
      type: actionType.ADD_ITEM_SUCCESS,  
      payload,
    };
  };
  export const AdditemError = (payload) => {
    return {
      type: actionType.ADD_ITEM_ERROR,
      payload,
    };
  };


export const Listitem = (payload) => {
    return {
      type: actionType.LIST_ITEM,
      payload,
    };
  };
  export const ListitemSucces = (payload) => {
    return {
      type: actionType.LIST_ITEM_SUCCESS,  
      payload,
    };
  };
  export const ListitemError = (payload) => {
    return {
      type: actionType.LIST_ITEM_ERROR,
      payload,
    };
  };


export const Deleteitem = (payload) => {
    return {
      type: actionType.DELETE_ITEM,
      payload,
    };
  };
  export const DeleteitemSucces = (payload) => {
    return {
      type: actionType.DELETE_ITEM_SUCCESS,  
      payload,
    };
  };
  export const DeleteitemError = (payload) => {
    return {
      type: actionType.DELETE_ITEM_ERROR,
      payload,
    };
  };