
import { IoMdHome, IoMdList } from "react-icons/io";
import { MdBarChart, MdDashboard, MdBookmark } from "react-icons/md";
import { IoMdBookmarks } from "react-icons/io";
import Widget from "components/widget/Widget";

import Widget2 from "components/widget/Widget2";
import { FcSalesPerformance } from "react-icons/fc";
import { useDispatch, useSelector } from "react-redux";
import { TotalView } from "../../../Redux/Master/action";
import { useEffect, useState } from "react";
import { FaRupeeSign } from "react-icons/fa";


const Dashboard = () => {
  const handleNavigate = (path) => {
    window.location.href = path;
  };
const [apiToken, setApiToken] = useState('');


  const dispatch =useDispatch()
  const TempleID = JSON.parse(localStorage.getItem("Sopanam_TempleId"));
  const totalviewvalue =useSelector((state)=>state.Master.viewdata)
  
  useEffect(()=>{
dispatch(TotalView({
  view:TempleID
}))
  },[])
  return (
    <div>
       
      <div className="w-full flex space-x-2 mb-5">
{/* <div className="w-1/3 flex ">
      <div className="w-full mt-2  bg-gray-100  ">
      <div className=" bg-white rounded-lg shadow-sm p-5 border-dashed border border-blue-500 flex flex-col sm:flex-row justify-between items-center gap-2 sm:gap-0">
        <div className="flex flex-col sm:flex-row justify-start items-center gap-4">
          <div className="bg-blue-200 flex p-2 rounded-md">
            
             <FcSalesPerformance/>
          </div>
          <div className="text-center sm:text-left">
            <h1 className="text-gray-700 font-bold tracking-wider">Total Sale</h1>
            <p className=" font-semibold block flex"><b>2000  </b><FaRupeeSign className="mt-1"/> </p>
          </div>
        </div>
       
      </div>
    </div>
    
    </div> */}
    {/* <div className="w-1/3 flex ">
      <div className="w-full mt-2  bg-gray-100  ">
      <div className="bg-white rounded-lg shadow-sm p-5 border-dashed border border-blue-500 flex flex-col sm:flex-row justify-between items-center gap-2 sm:gap-0">
        <div className="flex flex-col sm:flex-row justify-start items-center gap-4">
          <div className="bg-blue-200 flex p-2 rounded-md">
           
            <FcSalesPerformance />
           
          </div>
          <div className="text-center sm:text-left">
            <h1 className="text-gray-700 font-bold tracking-wider">Total Purchase</h1>
            <p className=" font-semibold block flex"><b>2000  </b><FaRupeeSign className="mt-1"/> </p>
          </div>
        </div>
       
      </div>
    </div>
    
    </div> */}
    {/* <div className="w-1/3 flex ">
      <div className="w-full mt-2  bg-gray-100  ">
      <div className=" bg-white rounded-lg shadow-sm p-5 border-dashed border border-blue-500 flex flex-col sm:flex-row justify-between items-center gap-2 sm:gap-0">
        <div className="flex flex-col sm:flex-row justify-start items-center gap-4">
          <div className="bg-blue-200 flex p-2 rounded-md">
           
            <FcSalesPerformance />
            
          </div>
          <div className="text-center sm:text-left">
            <h1 className="text-gray-700 font-bold tracking-wider">Total Cash In Hand</h1>
            <p className=" font-semibold block flex"><b>2000  </b><FaRupeeSign className="mt-1"/> </p>
          </div>
        </div>
       
      </div>
    </div>
    
    </div> */}
    
    </div>
    


      {/* Card widget */}
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-2 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Issue"}
          to="/admin/Sale"
          // subtitle={"$340.5"}
        />
        <Widget
          icon={<MdBookmark className="h-6 w-6" />}
          title={"Purchase"}
          to="/admin/Purchasepage"
          // subtitle={"$642.39"}
        />
        <Widget
          icon={<IoMdBookmarks className="h-7 w-7" />}
          title={"Items"}
          to="/admin/Item"
          // subtitle={"$574.34"}
        />
        <Widget
          icon={<IoMdList className="h-6 w-6" />}
          title={"Party"}
          to="/admin/Party"
        />
      </div>
      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-1 lg:grid-cols-1 2xl:grid-cols-1 3xl:grid-cols-6">
        <Widget2
          icon={<MdBarChart className="h-7 w-7" />}
          title={"General Report"}
          // subtitle={"145"}
        />
        <Widget2
          icon={<IoMdHome className="h-6 w-6" />}
          title={"Pending Payments"}
          // subtitle={"$2433"}
        />
      </div>
      {/* Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        {/* <TotalSpent /> */}
        {/* <WeeklyRevenue /> */}
      </div>

      {/* Tables & Charts */}

      <div className="relative mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        {/* Check Table */}
        <div>
          {/* <CheckTable
            columnsData={columnsDataCheck}
            tableData={tableDataCheck}
          /> */}
        </div>

        {/* Traffic chart & Pie Chart */}

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          {/* <DailyTraffic /> */}
          {/* <PieChartCard /> */}
        </div>

        {/* Complex Table , Task & Calendar */}

        {/* <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */}

        {/* Task chart & Calendar */}

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          {/* <TaskCard /> */}
          <div className="grid grid-cols-1 rounded-[20px]">
            {/* <MiniCalendar /> */}
          </div>
        </div>
      </div>
   
    </div>
  );
};

export default Dashboard;
