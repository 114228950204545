import React from "react";
import { Link } from "react-router-dom";
import Card from "components/card";

const Widget = ({ icon, title, subtitle, to }) => {
  return (
    <Link to={to} className="!flex-row flex-grow items-center rounded-[20px] border-blue-200 border">
      <Card extra="!flex-row flex-grow items-center rounded-[20px]">
        <div className="ml-[18px] flex h-[90px] w-auto flex-row items-center">
          <div className="rounded-full bg-lightPrimary p-3 dark:bg-navy-700">
            <span className="flex items-center text-brand-500 dark:text-white">
              {icon}
            </span>
          </div>
        </div>

        <div className="h-50 ml-4 flex w-auto flex-col justify-center">
          
          <p className="font-dm text-sm font-medium  text-blue-gray-500">{title}</p>
          <h4 className="text-xl font-bold text-navy-700 dark:text-white">
            {subtitle}
          </h4>
        </div>
      </Card>
    </Link>
  );
};

export default Widget;
