import { actionType } from "./action";
export const InitialState = {
  PartyData: "",
  GetPartyData: "",
  addpartydata:"",
  getgroupdata:"",
  StateData:"",
  GetAllPartyData:""
};

const PartyReducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionType.PARTY:
      return {
        ...state,
        PartyData: "",
      };
    case actionType.PARTY_SUCCESS:
      return {
        ...state,
        PartyData: action.payload,
      };
    case actionType.PARTY_FAIL:
      return {
        ...state,
        PartyData: "error",
      };

      case actionType.GETPARTY:
      return {
        ...state,
        GetPartyData: "",
      };
    case actionType.GETPARTY_SUCCESS:
      return {
        ...state,
        GetPartyData: action.payload.Result,
      };
    case actionType.GETPARTY_FAIL:
      return {
        ...state,
        GetPartyData: "error",
      };



      case actionType.ADDPARTY:
        return {
          ...state,
          addpartydata: "",
        };
      case actionType.ADDPARTY_SUCCESS:
        return {
          ...state,
          addpartydata: action.payload
        };
      case actionType.ADDPARTY_FAIL:
        return {
          ...state,
          addpartydata: "error",
        };



       


          case actionType.STATE:
          return {
            ...state,
            StateData: "",
          };
        case actionType.STATE_SUCCESS:
          console.log("action.payload",action.payload);
          return {
            ...state,
            StateData: action.payload.Result,
          };
        case actionType.STATE_FAIL:
          return {
            ...state,
            StateData: "error",
          };




          case actionType.GETALLPARTY:
            return {
              ...state,
              GetAllPartyData: "",
            };
          case actionType.GETALLPARTY_SUCCESS:
            console.log("action.payload",action.payload);
            return {
              ...state,
              GetAllPartyData: action.payload.Result,
            };
          case actionType.GETALLPARTY_FAIL:
            return {
              ...state,
              GetAllPartyData: "error",
            };


    





    default:
      return state;
  }
};
export default PartyReducer;