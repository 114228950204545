export const actionType = {
    MASTER_PARTY_TYPE_ADD:"MASTER_PARTY_TYPE_ADD",
    MASTER_PARTY_TYPE_ADD_SUCCESS:"MASTER_PARTY_TYPE_ADD_SUCCESS",
    MASTER_PARTY_TYPE_ADD_ERROR:"MASTER_PARTY_TYPE_ADD_ERROR",

    MASTER_PARTY_TYPE_DELETE:"MASTER_PARTY_TYPE_DELETE",
    MASTER_PARTY_TYPE_DELETE_SUCCESS:"MASTER_PARTY_TYPE_DELETE_SUCCESS",
    MASTER_PARTY_TYPE_DELETE_ERROR:"MASTER_PARTY_TYPE_DELETE_ERROR",


    MASTER_PRICE_GROUP_ADD:"MASTER_PRICE_GROUP_ADD",
    MASTER_PRICE_GROUP_ADD_SUCCESS:"MASTER_PRICE_GROUP_ADD_SUCCESS",
    MASTER_PRICE_GROUP_ADD_ERROR:"MASTER_PRICE_GROUP_ADD_ERROR",

    MASTER_PRICE_GROUP_DELETE:"MASTER_PRICE_GROUP_DELETE",
    MASTER_PRICE_GROUP_DELETE_SUCCESS:"MASTER_PRICE_GROUP_DELETE_SUCCESS",
    MASTER_PRICE_GROUP_DELETE_ERROR:"MASTER_PRICE_GROUP_DELETE_ERROR",

    MASTER_PRICE_GROUP_LIST:"MASTER_PRICE_GROUP_LIST",
    MASTER_PRICE_GROUP_LIST_SUCCESS:"MASTER_PRICE_GROUP_LIST_SUCCESS",
    MASTER_PRICE_GROUP_LIST_ERROR:"MASTER_PRICE_GROUP_LIST_ERROR",


    UNITS:"UNITS",
    UNITS_SUCCESS:"UNITS_SUCCESS",
    UNITS_ERROR:"UNITS_ERROR",

    VIEW_TOTAL:"VIEW_TOTAL",
    VIEW_TOTAL_SUCCESS:"VIEW_TOTAL_SUCCESS",
    VIEW_TOTAL_ERROR:"VIEW_TOTAL_ERROR",




    
}

export const PartyTypeAddMaster = (payload) => {
    return {
      type: actionType.MASTER_PARTY_TYPE_ADD,
      payload,
    };
  };
  export const PartyTypeAddMasterSucces = (payload) => {
    return {
      type: actionType.MASTER_PARTY_TYPE_ADD_SUCCESS,  
      payload,
    };
  };
  export const PartyTypeAddMasterError = (payload) => {
    return {
      type: actionType.MASTER_PARTY_TYPE_ADD_ERROR,
      payload,
    };
  };

export const PartyTypeDeleteMaster = (payload) => {
    return {
      type: actionType.MASTER_PARTY_TYPE_DELETE,
      payload,
    };
  };
  export const PartyTypeDeleteMasterSucces = (payload) => {
    return {
      type: actionType.MASTER_PARTY_TYPE_DELETE_SUCCESS,  
      payload,
    };
  };
  export const PartyTypeDeleteMasterError = (payload) => {
    return {
      type: actionType.MASTER_PARTY_TYPE_DELETE_ERROR,
      payload,
    };
  };


export const PriceGroupAddMaster = (payload) => {
    return {
      type: actionType.MASTER_PRICE_GROUP_ADD,
      payload,
    };
  };
  export const PriceGroupAddMasterSucces = (payload) => {
    return {
      type: actionType.MASTER_PRICE_GROUP_ADD_SUCCESS,  
      payload,
    };
  };
  export const PriceGroupAddMasterError = (payload) => {
    return {
      type: actionType.MASTER_PRICE_GROUP_ADD_ERROR,
      payload,
    };
  };


export const PriceGroupDeleteMaster = (payload) => {
    return {
      type: actionType.MASTER_PRICE_GROUP_DELETE,
      payload,
    };
  };
  export const PriceGroupDeleteMasterSucces = (payload) => {
    return {
      type: actionType.MASTER_PRICE_GROUP_DELETE_SUCCESS,  
      payload,
    };
  };
  export const PriceGroupDeleteMasterError = (payload) => {
    return {
      type: actionType.MASTER_PRICE_GROUP_DELETE_ERROR,
      payload,
    };
  };


export const PriceGroupListMaster = (payload) => {
    return {
      type: actionType.MASTER_PRICE_GROUP_LIST,
      payload,
    };
  };
  export const PriceGroupListMasterSucces = (payload) => {
    return {
      type: actionType.MASTER_PRICE_GROUP_LIST_SUCCESS,  
      payload,
    };
  };
  export const PriceGroupListMasterError = (payload) => {
    return {
      type: actionType.MASTER_PRICE_GROUP_LIST_ERROR,
      payload,
    };
  };


export const unitsmeasure = (payload) => {
    return {
      type: actionType.UNITS,
      payload,
    };
  };
  export const unitsmeasureSucces = (payload) => {
    return {
      type: actionType.UNITS_SUCCESS,  
      payload,
    };
  };
  export const unitsmeasureError = (payload) => {
    return {
      type: actionType.UNITS_ERROR,
      payload,
    };
  };

export const TotalView = (payload) => {
    return {
      type: actionType.VIEW_TOTAL,
      payload,
    };
  };
  export const TotalViewSucces = (payload) => {
    return {
      type: actionType.VIEW_TOTAL_SUCCESS,  
      payload,
    };
  };
  export const TotalViewError = (payload) => {
    return {
      type: actionType.VIEW_TOTAL_ERROR,
      payload,
    };
  };


