// ** Redux Imports
import { combineReducers } from 'redux'

import Loader from './Loader/reducer'
// import pickLocation from './pickLo/cation/reducer'


const commonReducer = combineReducers({
    Loader,
    // pickLocation
})

export default commonReducer