/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";

import SidebarCard from "components/sidebar/componentsrtl/SidebarCard";
import routes from "routes.js";
import SopanamImg from "../../assets/img/auth/sopanam.png"

const Sidebar = ({ open, onClose }) => {
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex h-screen flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>
      
      <div className="justify-center flex"> 
        <img 
          src={SopanamImg} 
          className="w-full h-auto max-w-[200px] object-contain" 
          alt="Sopanam Image" 
        />
      </div>
      {/* <div className={`mx-[40px] mt-[50px] flex items-center`}>
        <div className="mt-1  h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white">
          SOPANAM <span class="font-medium">STORE</span>
        </div>
      </div> */}
      <div class="mt-[20px] mb-7 h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}
      <ul className="mb-auto h-full overflow-y-auto  pt-1">
        <Links routes={routes} />
      </ul>

      {/* Free Horizon Card */}
      <div className="flex justify-center">
        <SidebarCard />
      </div>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
