import {takeEvery,put,call,all} from "redux-saga/effects"
import { actionType, ItemFastBillingError, ItemFastBillingSuccess, PurchaseInsertFail, PurchaseInsertSuccess,
  SaleInsertSuccess,SaleInsertFail,PrintPurchaseSuccess,PrintPurchaseFail} from "./action"
import SaleService from "../../Service/SaleService"
import { startLoader,stopLoader } from "../../Redux/Common/Loader/action";


function* ItemFastBilling({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(SaleService.ItemFastBilling, payload);
    yield put(ItemFastBillingSuccess(data));
  } catch (error) {
    yield put(ItemFastBillingError(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}




function* PurchaseInsert({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(SaleService.PurchaseInsert, payload);
    yield put(PurchaseInsertSuccess(data));
  } catch (error) {
    yield put(PurchaseInsertFail(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}

function* SaleInsert({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(SaleService.SaleInsert, payload);
    yield put(SaleInsertSuccess(data));
  } catch (error) {
    yield put(SaleInsertFail(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}


function* PrintPurchase({ payload }) {
  try {
    yield put(startLoader());
    const data = yield call(SaleService.PrintPurchase, payload);
    yield put(PrintPurchaseSuccess(data));
  } catch (error) {
    yield put(PrintPurchaseFail(error));
    console.log(error);
  } finally {
    yield put(stopLoader());
  }
}


export default function* rootsaga(){
  yield all([takeEvery(actionType.ITEM_FASTBILLING, ItemFastBilling)]);
  yield all([takeEvery(actionType.PURCHASE_INSERT, PurchaseInsert)]);
  yield all([takeEvery(actionType.SALE_INSERT, SaleInsert)]);
  yield all([takeEvery(actionType.PRINTPURCHASE, PrintPurchase)]);

  }
