import Card from "components/card";
import { BsPlusCircleFill } from "react-icons/bs";
import { useEffect, useState } from "react";
import DialogDemo from "./components/Modal";
import { useDispatch, useSelector } from "react-redux";
import { AdditemError, Deleteitem, Listitem } from "../../../Redux/Items/action";
import toast from "react-hot-toast";
import { MdDeleteForever, MdEditNote } from "react-icons/md";

const Marketplace = () => {
  const [open, setOpen] = useState(false);
  const [Editvalue, setEditvalue] = useState();
  const TempleId = JSON.parse(localStorage.getItem('Sopanam_TempleId'));
  const dispatch = useDispatch()
  const ItemData = useSelector((state) => state.Items.ListItemData);
  const ItemAdd = useSelector((state) => state.Items.AddItemData);
  const Itemdelete = useSelector((state) => state.Items.deleteitemdata);
  

  useEffect(()=>{
    dispatch(Listitem({
      TempleId:TempleId
    }))
    
  },[ItemAdd,Itemdelete])

  useEffect(() => {
    if (ItemAdd.Id > 0) {
      toast.success("Item Succuss");
      dispatch(AdditemError());
    }
  }, [ItemAdd]);
  return (
    <div className="mt-3  h-full grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-3">
    <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
      <div className="mt-5  h-full grid-cols-1 gap-5 md:grid-cols-2">
        <Card extra={"w-full h-full p-4"}>
          <div className="relative flex items-center justify-between">
            <div className="pb-5 text-xl font-bold text-navy-700 dark:text-white">
              ITEM 
            </div>
            <div>
              <button
                data-modal-toggle="crud-modal"
                onClick={() =>{
                  setEditvalue("")
                  setOpen(!open)
                }
                }
                className={`flex items-center text-xl hover:cursor-pointer ${"text-brand-green mb-2 mr-5 bg-lightPrimary p-2 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10"} linear justify-center rounded-lg font-bold transition duration-200`}
              >
                <BsPlusCircleFill className="h-6 w-6" />
              </button>
            </div>
          </div>

          <div className="h-full md:h-[420px] md:overflow-y-auto ">
            <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
              <table className="w-full text-left text-sm text-gray-500 rtl:text-right dark:text-gray-400">
                <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col" className="px-6 py-3">
                      Sl No
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Item Name
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Code
                    </th>
                    <th scope="col" className="px-6 py-3">
                      GST
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Price
                    </th>
                    <th scope="col" className="px-6 py-3">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                 
                  {ItemData.length?ItemData?.map((item, i) => (
          <tr key={i}>
            <td className="px-6 py-4 ">{ i + 1}</td>
            <td className="px-6 py-4 ">{item.Name}</td>
            <td className="px-6 py-4 ">{item.Code}</td>
            <td className="px-6 py-4 ">{item.Tax}</td>
            <td className="px-6 py-4 ">{`${item.Rate} . 00`}</td>
           
                       <td className="px-6 py-4 whitespace-nowrap">
                       <button
                            type="button"
                            className="text-green-600  mr-5 "
                            onClick={() =>{
                              setEditvalue(item)
                              setOpen(!open)
                            }
                            }
                          >
                            <MdEditNote
                              className="mt-1"
                              style={{ fontSize: "1.5rem" }}
                            
                              />
                          </button>
              <button
              type="button"
                className="text-red-600 hover:text-red-900"
                onClick={() => {
                 dispatch(Deleteitem({
                  ItemId:item.Id,
                  TempleId:TempleId
                 }))
                }}
              >
                <MdDeleteForever className="mt-1" style={{ fontSize: "1.5rem" }} />
              </button>
             

            </td>
          </tr>
        )):<div className="items-center"> No Data Found</div>}
                </tbody>
              </table>
            </div>
          </div>
        </Card>
      </div>
    </div>
    {open && <DialogDemo  open={open} setOpen={setOpen}Editvalue={Editvalue}/>}
  </div>
  );
};

export default Marketplace;
