import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";


import AdminLayout from "layouts/admin";
import SignIn from "views/auth/SignIn"
import SpinnerComponent from "components/Spinner/Spinner";
import PurchasePrint from "views/Print/PurchasePrint";
const App = () => {
  return (
    <div>
    <Routes>
      <Route path="admin/*" element={<AdminLayout />} />
      <Route path="auth/sign-in" element={<SignIn />} />
      <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
      <Route path="/Print" element={<PurchasePrint/>} />


     
    </Routes>
    <SpinnerComponent/>
    </div>
    
  );
};

export default App;
