import CardMenu from "components/card/CardMenu";
import Card from "components/card";
import * as XLSX from 'xlsx';
import { RiFileExcel2Line } from "react-icons/ri";
import { IoPrintOutline } from "react-icons/io5";
import React, { useEffect, useMemo } from "react";

import { useDispatch, useSelector } from "react-redux";
import { StockReport } from "../../../Redux/Report/action";
import ReactToPrint from "react-to-print";
import StockPrint from "../../../views/Print/StockPrint";



const StockReportpage = () => {
  
    const TempleID = JSON.parse(localStorage.getItem("Sopanam_TempleId"));
    const stocklist =useSelector((state)=>state.Report.stock)

    console.log("mainstocklist",stocklist);
const dispatch =useDispatch();

useEffect(()=>{
    dispatch(StockReport({
        TempleId:TempleID
    }))
},[])

const OpeningStockvalue = stocklist.length&&stocklist?.reduce(
  (total, item) => total + item.OpeningStockValue,
  0
);
const StockInHands = stocklist.length&&stocklist?.reduce(
  (total, item) => total + item.StockInHands,
  0
);
const ClosingStock = stocklist.length&&stocklist?.reduce(
  (total, item) => total + item.ClosingStock,
  0
);


const exportToExcel = () => {
  let newArray = stocklist.map((obj) => {
    let newObj = { ...obj };

    delete newObj.Id;
    return newObj;
  });

  console.log("newArray",newArray);

  const dataArray = newArray.map((item) => Object.values(item));
  console.log("dataArray",dataArray);
  const headers = [
    "S.No",
    "Name",
    "Opening Stock",
    "Opening Stock Value ",
    "Issue Quantity",
    "Issue Return",
    "Purchase Quantity",
    "Purchase Return",
    "Damage Quantity",
    "average",
    "Stock In Hands",
    "Closing Stock",
  ];

 

  const wsData = [
  
    headers,
    ...dataArray.map((item, index) => [
      index + 1,
      stocklist[index].Name,
      stocklist[index].OpeningStock,
      stocklist[index].OpeningStockValue,
      stocklist[index].SaleQuantity,
      stocklist[index].IssueReturn,
      stocklist[index].PurchaseQuantity,
      stocklist[index].PurchaseReturn,
      stocklist[index].DamageQuantity,
      stocklist[index].average,
      stocklist[index].StockInHands,
      stocklist[index].ClosingStock,
    ]),
  ];

  console.log("wsData",wsData);
  const ws = XLSX.utils.aoa_to_sheet(wsData);

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

  XLSX.writeFile(wb, "Stock Report.xlsx");
};
    
const componentRef = React.useRef();


  return (
    <div>
<StockPrint   ref={componentRef} stocklist={stocklist}/>
    <Card extra={"w-full h-full p-4"}>
      <div className="relative flex items-center justify-between">
        <div className="pb-5 text-xl font-bold text-navy-700 dark:text-white">
        Stock 
        </div>
        <div>
        <button size={24} className='mr-5' onClick={exportToExcel}><RiFileExcel2Line size={24} color='green'/></button>
        <ReactToPrint
                trigger={() => (
                  <button type='button'className="PrintButton margin">
                    <IoPrintOutline size={24} color='blue'/>
                    
                  </button>
                )}
                content={() => componentRef.current}
              />
       
        </div>
      </div>

      <div className="h-full md:h-[500px] md:overflow-y-auto ">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-left text-sm text-gray-500 rtl:text-right dark:text-gray-400">
            <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  SL NO
                </th>
                <th scope="col" className="px-6 py-3">
                Name
                </th>
                <th scope="col" className="px-6 py-3">
                Opening Stock
                </th>
                <th scope="col" className="px-6 py-3">
                Opening Stock Value
                </th>
                <th scope="col" className="px-6 py-3">
                Issue Quantity
                </th>
                <th scope="col" className="px-6 py-3">
                Issue Return
                </th>
                <th scope="col" className="px-6 py-3">
                Purchase Quantity
                </th>
                <th scope="col" className="px-6 py-3">
                Purchase Return
                </th>
                <th scope="col" className="px-6 py-3">
                Damage Quantity
                </th>
                <th scope="col" className="px-6 py-3">
                Average
                </th>
                <th scope="col" className="px-6 py-3">
                Stock In Hand 
                </th>
                <th scope="col" className="px-6 py-3">
                Closing Stock Value
                </th>

              </tr>
            </thead>
            <tbody>
  {Array.isArray(stocklist) && stocklist.map((item, index) => (
    <tr key={index} className={`border-b ${index % 2 === 0 ? 'even:bg-gray-50 dark:bg-gray-800' : 'odd:bg-white'} ${index % 2 === 0 ? 'even:bg-gray-800 dark:bg-gray-800' : 'odd:bg-gray-900'}`}>
      <td className="px-6 py-4 text-gray-900">{index + 1}</td>
      <td className="px-6 py-4 text-gray-900">{item.Name}</td>
      <td className="px-6 py-4 text-gray-900">{item.OpeningStock}</td>
      <td className="px-6 py-4 text-gray-900">{item.OpeningStockValue.toFixed(2)}.00</td>
      <td className="px-6 py-4 text-gray-900">{item.SaleQuantity}</td>
      <td className="px-6 py-4 text-gray-900">{item.IssueReturn}</td>
      <td className="px-6 py-4 text-gray-900">{item.PurchaseQuantity}</td>
      <td className="px-6 py-4 text-gray-900">{item.PurchaseReturn}</td>
      <td className="px-6 py-4 text-gray-900">{item.DamageQuantity}</td>
      <td className="px-6 py-4 text-gray-900">{item.average.toFixed(2)}</td>
      <td className="px-6 py-4 text-gray-900 font-bold">{item.StockInHands}</td>
      <td className="px-6 py-4 text-gray-900 font-bold">{item.ClosingStock}</td>
    </tr>
  ))}
  <tr >
                        <td colSpan={2} className="mt-1 "><div className=" mt-5 p-1" style={{color:'black',marginLeft:'80px'}}><b>Total :</b> </div></td>
                        <td></td>
                        <td> <div className=" flex  mt-5 ml-4 p-1" style={{color:'black'}}> <b>{OpeningStockvalue}.00 </b> </div> </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td> 
                        <td></td>
                        <td > <div  className="ml-6 mt-5" style={{color:'black'}} > <b>{StockInHands} </b> </div> </td>
                        <td colSpan={8}> <div className=" flex  mt-5 ml-4 p-1" style={{color:'black'}} > <b>{ClosingStock} </b> </div> </td>


                      </tr>

</tbody>

          </table>
        </div>
      </div>
    </Card>
    </div>
  );
};

export default StockReportpage;
