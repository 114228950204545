import axios from "axios";
import { apiurl } from "configs/config";
import ApiClient from "./ApiClient";


class MasterService {
 constructor(callback) {
        this.callback = callback
    }

   
    async PartyTypeAddMaster(payload) {
        const response = await axios.post(
            `${apiurl}/api/PartyType/SetPartyType`,
            payload,
        ).then((response) => {
            return response.data;
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
   
   
    async PartyTypeDeleteMaster(payload) {
        const response = await axios.post(
            `${apiurl}/api/PartyType/DeletePartyType?Pid=${payload.Pid}&TempleId=${payload.TempleId}`,
          
        ).then((response) => {
            return response.data;
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
   
   
    async PriceGroupAddMaster(payload) {
        const response = await axios.post(
            `${apiurl}/api/PriceGroup/SetPriceGroup`,
            payload,
        ).then((response) => {
            return response.data;
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
   
    async PriceGroupDeleteMaster(payload) {
        const response = await axios.get(
            `${apiurl}/api/PriceGroup/DeletePriceGroupWithTempleId?TempleId=${payload.TempleId}&PgID=${payload.PgID}`,
            payload,
        ).then((response) => {
            return response.data;
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
   
    async PriceGroupListMaster(payload) {
        const response = await axios.get(
            `${apiurl}/api/PriceGroup/GetAllPriceGroup?TempleId=${payload.TempleId}`,
            
        ).then((response) => {
            return response.data;
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
   
   
    async unitsmeasure(payload) {
        const response = await axios.get(
            `${apiurl}/api/Master/GetUnitOfMessurs`,payload
            
        ).then((response) => {
            return response.data;
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
   
    async TotalView(payload) {
        const response = await axios.get(
            `${apiurl}/api/Transaction/GetAllViewTotalWithTempleId?view=${payload.view}`,
            
        ).then((response) => {
            return response.data;
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
   
   
    
   
   
    
}
export default new MasterService();