import { actionType } from "./action";
export const InitialState = {
  Partytypeadd: "",
  Partytypedelete: "",
  pricegroupadd:'',
  pricegroupdelete:'',
  pricegrouplist:'',
  unitvalue:'',
  viewdata:''
  
};

const SaleReducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionType.MASTER_PARTY_TYPE_ADD:
      return { ...state, Partytypeadd: "" };
  case actionType.MASTER_PARTY_TYPE_ADD_SUCCESS:
      return { ...state, Partytypeadd: action.payload.Result};
  case actionType.MASTER_PARTY_TYPE_ADD_ERROR:
      return { ...state, Partytypeadd: "error" };

    
    case actionType.MASTER_PARTY_TYPE_DELETE:
      return { ...state, Partytypedelete: "" };
  case actionType.MASTER_PARTY_TYPE_DELETE_SUCCESS:
      return { ...state, Partytypedelete: action.payload.Result};
  case actionType.MASTER_PARTY_TYPE_DELETE_ERROR:
      return { ...state, Partytypedelete: "error" };

    
    case actionType.MASTER_PRICE_GROUP_ADD:
      return { ...state, pricegroupadd: "" };
  case actionType.MASTER_PRICE_GROUP_ADD_SUCCESS:
      return { ...state, pricegroupadd: action.payload.Result };
  case actionType.MASTER_PRICE_GROUP_ADD_ERROR:
      return { ...state, pricegroupadd: "error" };

    
    case actionType.MASTER_PRICE_GROUP_DELETE:
      return { ...state, pricegroupdelete: "" };
  case actionType.MASTER_PRICE_GROUP_DELETE_SUCCESS:
      return { ...state, pricegroupdelete: action.payload.Result };
  case actionType.MASTER_PRICE_GROUP_DELETE_ERROR:
      return { ...state, pricegroupdelete: "error" };

    
    case actionType.MASTER_PRICE_GROUP_LIST:
      return { ...state, pricegrouplist: "" };
  case actionType.MASTER_PRICE_GROUP_LIST_SUCCESS:
      return { ...state, pricegrouplist: action.payload.Result };
  case actionType.MASTER_PRICE_GROUP_LIST_ERROR:
      return { ...state, pricegrouplist: "error" };

    
    case actionType.UNITS:
      return { ...state, unitvalue: "" };
  case actionType.UNITS_SUCCESS:
      return { ...state, unitvalue: action.payload.Result};
  case actionType.UNITS_ERROR:
      return { ...state, unitvalue: "error" };

    
    case actionType.VIEW_TOTAL:
      return { ...state, viewdata: "" };
  case actionType.VIEW_TOTAL_SUCCESS:
      return { ...state, viewdata: action.payload.Result};
  case actionType.VIEW_TOTAL_ERROR:
      return { ...state, viewdata: "error" };

    

    default:
      return state;
  }
};
export default SaleReducer;