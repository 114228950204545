import React from 'react'


  const StockPrint= React.forwardRef((stocklist, ref) =>  {


  const OpeningStockvalue = stocklist.stocklist.length&&stocklist.stocklist?.reduce(
    (total, item) => total + item.OpeningStockValue,
    0
  );
  const StockInHands = stocklist.stocklist.length&&stocklist.stocklist?.reduce(
    (total, item) => total + item.StockInHands,
    0
  );
  const ClosingStock = stocklist.stocklist.length&&stocklist.stocklist?.reduce(
    (total, item) => total + item.ClosingStock,
    0
  );
  return (
    <div style={{display:'none'}}>
       <div className="h-full md:h-[500px]" ref={ref}>

       <div className="pb-5 text-xl  mt-8 text-center font-bold text-navy-700 dark:text-white">
        Stock Report
        </div>
        <div className="relative  shadow-md sm:rounded-lg">
          <table className="w-full text-left text-sm text-gray-500 rtl:text-right dark:text-gray-400" >
            <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  SL NO
                </th>
                <th scope="col" className="px-6 py-3">
                Name
                </th>
                <th scope="col" className="px-6 py-3">
                Opening Stock
                </th>
                <th scope="col" className="px-6 py-3">
                Opening Stock Value
                </th>
                <th scope="col" className="px-6 py-3">
                Issue Quantity
                </th>
                <th scope="col" className="px-6 py-3">
                Issue Return
                </th>
                <th scope="col" className="px-6 py-3">
                Purchase Quantity
                </th>
                <th scope="col" className="px-6 py-3">
                Purchase Return
                </th>
                <th scope="col" className="px-6 py-3">
                Damage Quantity
                </th>
                <th scope="col" className="px-6 py-3">
                Average
                </th>
                <th scope="col" className="px-6 py-3">
                Stock In Hand 
                </th>
                <th scope="col" className="px-6 py-3">
                Closing Stock
                </th>

              </tr>
            </thead>
            <tbody>
  {Array.isArray(stocklist.stocklist) && stocklist.stocklist.map((item, index) => (
    <tr key={index} className={`border-b ${index % 2 === 0 ? 'even:bg-gray-50 dark:bg-gray-800' : 'odd:bg-white'} ${index % 2 === 0 ? 'even:bg-gray-800 dark:bg-gray-800' : 'odd:bg-gray-900'}`}>
      <td className="px-6 py-4 text-gray-900">{index + 1}</td>
      <td className="px-6 py-4 text-gray-900">{item.Name}</td>
      <td className="px-6 py-4 text-gray-900">{item.OpeningStock}</td>
      <td className="px-6 py-4 text-gray-900">{item.OpeningStockValue}.00</td>
      <td className="px-6 py-4 text-gray-900">{item.SaleQuantity}</td>
      <td className="px-6 py-4 text-gray-900">{item.IssueReturn}</td>
      <td className="px-6 py-4 text-gray-900">{item.PurchaseQuantity}</td>
      <td className="px-6 py-4 text-gray-900">{item.PurchaseReturn}</td>
      <td className="px-6 py-4 text-gray-900">{item.DamageQuantity}</td>
      <td className="px-6 py-4 text-gray-900">{item.average}.00</td>
      <td className="px-6 py-4 text-gray-900 font-bold">{item.StockInHands}</td>
      <td className="px-6 py-4 text-gray-900 font-bold">{item.ClosingStock}</td>
    </tr>
  ))}
  <tr >
                        <td colSpan={2} className="mt-1 "><div className=" mt-5 p-1" style={{color:'black',marginLeft:'80px'}}><b>Total :</b> </div></td>
                        <td></td>
                        <td> <div className=" flex  mt-5 ml-4 p-1" style={{color:'black'}}> <b>{OpeningStockvalue}.00 </b> </div> </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td> 
                        <td></td>
                        <td > <div  className="ml-6 mt-5" style={{color:'black'}} > <b>{StockInHands} </b> </div> </td>
                        <td colSpan={8}> <div className=" flex  mt-5 ml-4 p-1" style={{color:'black'}} > <b>{ClosingStock} </b> </div> </td>


                      </tr>

</tbody>

          </table>
        </div>
      </div>
    </div>
 );
});

export default StockPrint