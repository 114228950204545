import Footer from "components/footer/FooterAuthDefault";
import authImg from "assets/img/auth/SopanamTempleNw.jpg";
import { Link, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import routes from "routes.js";
import toast, { Toaster } from 'react-hot-toast';
import { useForm } from "react-hook-form";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Login } from "../../Redux/Authentication/action";
import Otp from "./Otp";
import '@fortawesome/fontawesome-free/css/all.css';
import "./SignIn.css"
import SopanamLogo from "../../assets/img/auth/sopanam.png"

export default function Auth() {
  const loginDatavalue = useSelector((state) => state.Authentication.LoginData);
  const [apiToken, setApiToken] = useState('');
const navigate =useNavigate()
  const dispatch = useDispatch();
  const TempleId = JSON.parse(localStorage.getItem('Sopanam_TempleId'));
  const UserId = JSON.parse(localStorage.getItem("Sopanam_UserId"));
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm(

  );

  useEffect(() => {
   console.log("errors",errors);
  }, [errors])
  
  useEffect(() => {
    if (loginDatavalue && loginDatavalue.StatusCode === 200) {
      reset();
    } else if (loginDatavalue && loginDatavalue.StatusCode === 404) {
      toast.error("Invalid credentials");
    }
  }, [loginDatavalue, reset, toast]);
  const [Temple, setTemple] = useState(''); // State for Temple ID
  const [user, setUser] = useState(''); // State for User ID

  useEffect(() => {
    // Function to parse parameters from URL query parameters
    const parseParamsFromUrl = () => {
      const params = new URLSearchParams(window.location.search);
      const token = params.get('apiToken');
      const templeId = params.get('temple');
      const userId = params.get('user');
      return { token, templeId, userId };
    };

    // Parse parameters from URL when component mounts
    const { token, templeId, userId } = parseParamsFromUrl();
    setApiToken(token);
    setTemple(templeId);
    setUser(userId);

    // Save API token to localStorage
    localStorage.setItem('ApiToken_SopanamSTORE', JSON.stringify(token));
    localStorage.setItem('Sopanam_UserId', JSON.stringify(userId))
    localStorage.setItem('Sopanam_TempleId', JSON.stringify(templeId))

   
    // Log values for debugging
    console.log("API Token:", token);
    console.log("Temple ID:", templeId);
    console.log("User ID:", userId);
  }, []);



  useEffect(()=>{
    if(Temple&&user){
      navigate("/admin/Dashboad")
    }
  },[Temple,user])
 
  const HandelSave = (data) => {
    console.log(data, "data");
    dispatch(
      Login({
        EmailId: data.email,
        Password: data.password,
      })
    );
  };
 
  
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route path={`/${prop.path}`} element={prop.component} key={key} />
        );
      } else {
        return null;
      }
    });
  };
  document.documentElement.dir = "ltr";
  return (
    <div className="b" >
      
        {loginDatavalue.StatusCode === 200 ?(<Otp />):<div className="mt-1 mb-1 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start ">
      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900 ">
        <FixedPlugin />
        <main className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div className="mb-auto flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
              <div className="w-1/6 justify-start flex absolute top-[-15px] left-0">
        <img src={SopanamLogo} className="h-[100px]" alt="" srcset="" />

      </div>
              <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white mt-10">
          Sign In
        </h4>
        <p className="mb-9 ml-1 text-base text-gray-600">
          Enter your email and password to sign in!
        </p>

        <form onSubmit={handleSubmit(HandelSave)}>
          
          <label htmlFor="Email">Email</label>
          <input
            type="text"
            {...register("email",{required:true})}
            className="mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none "
            id=""
          />
          {errors.email && <span style={{color:"red",fontWeight:"bold",fontSize:"0.7rem"}}>Please Enter Your Email </span>} <br />

          <label htmlFor="Paaword mt-2">Password</label>
          <div className="password-input-container">
      <input
        type={showPassword ? "text" : "password"}
        {...register("password", { required: true })}
        className="password-input mt-2 flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none"
        id="passwordInput"
      />
      <div className="password-toggle" onClick={togglePasswordVisibility}>
        <i className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"}`} />
      </div>
      {errors.password && (
        <span style={{ color: "red", fontWeight: "bold", fontSize: "0.7rem" }}>
          Please Enter Your Password
        </span>
      )}
    </div>

         
          <button
            type="submit"
            className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            Sign In
          </button>
        </form>
        
      </div>
                {loginDatavalue.StatusCode === 200 ?(""):
                <div className="absolute right-0 hidden h-full min-h-screen md:block lg:w-[49vw] 2xl:w-[44vw]">
                  <div
                    className="absolute flex h-full w-full items-end justify-center bg-cover bg-center lg:rounded-bl-[120px] xl:rounded-bl-[200px] "
                    style={{ backgroundImage: `url(${authImg})` }}
                  />
                </div>
}
              </div>
              {/* <Footer /> */}
            </div>
          </div>
        </main>
      </div>
      </div>}
    </div>
  );
}
