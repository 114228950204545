export const actionType = {
  ITEM_FASTBILLING: "ITEM_FASTBILLING",
  ITEM_FASTBILLING_SUCCESS: "ITEM_FASTBILLING_SUCCESS",
  ITEM_FASTBILLING_ERROR: "ITEM_FASTBILLING_ERROR",

  PURCHASE_INSERT: "PURCHASE_INSERT",
  PURCHASE_INSERT_SUCCESS: "PURCHASE_INSERT_SUCCESS",
  PURCHASE_INSERT_ERROR: "PURCHASE_INSERT_ERROR",

  SALE_INSERT: "SALE_INSERT",
  SALE_INSERT_SUCCESS: "SALE_INSERT_SUCCESS",
  SALE_INSERT_ERROR: "SALE_INSERT_ERROR",


  PRINTPURCHASE : "PRINT_PURCHASE",
  PRINT_PURCHASE_SUCCESS : "PRINT_PURCHASE_SUCCESS",
  PRINT_PURCHASE_ERROR : "PRINT_PURCHASE_ERROR",
};

export const ItemFastBilling = (payload) => {
  return {
    type: actionType.ITEM_FASTBILLING,
    payload,
  };
};

export const ItemFastBillingSuccess = (payload) => {
  return {
    type: actionType.ITEM_FASTBILLING_SUCCESS,
    payload,
  };
};

export const ItemFastBillingError = (payload) => {
  return {
    type: actionType.ITEM_FASTBILLING_ERROR,
    payload,
  };
};

export const PurchaseInsert = (payload) => {
  return {
    type: actionType.PURCHASE_INSERT,
    payload,
  };
};

export const PurchaseInsertSuccess = (payload) => {
  return {
    type: actionType.PURCHASE_INSERT_SUCCESS,
    payload,
  };
};

export const PurchaseInsertFail = (payload) => {
  return {
    type: actionType.PURCHASE_INSERT_ERROR,
    payload,
  };
};

export const SaleInsert = (payload) => {
  return {
    type: actionType.SALE_INSERT,
    payload,
  };
};

export const SaleInsertSuccess = (payload) => {
  return {
    type: actionType.SALE_INSERT_SUCCESS,
    payload,
  };
};

export const SaleInsertFail = (payload) => {
  return {
    type: actionType.SALE_INSERT_ERROR,
    payload,
  };
};


export const PrintPurchase = (payload) => {
  return {
    type: actionType.PRINTPURCHASE,
    payload,
  };
};

export const PrintPurchaseSuccess = (payload) => {
  return {
    type: actionType.PRINT_PURCHASE_SUCCESS,
    payload,
  };
};

export const PrintPurchaseFail = (payload) => {
  return {
    type: actionType.PRINT_PURCHASE_ERROR,
    payload,
  };
};






