import axios from 'axios';
import { apiurl } from '../configs/config';

export const apiToken = () => JSON.parse(localStorage.getItem('ApiToken_SopanamSTORE'));

export const customHeaders = {
    Accept: 'application/json',
    Authorization: `Bearer ${apiToken()}` || undefined
};

const axiosInstance = axios.create({
    baseURL: apiurl,
    headers: customHeaders,
});

axiosInstance.interceptors.request.use(function (config) {
    // Dynamically set the Authorization header before each request
    config.headers.Authorization = `Bearer ${apiToken()}` || undefined;
    return config;
}, function (error) {
    return Promise.reject(error);
});

export default axiosInstance;
