import { actionType } from "./action";
export const InitialState = {
  LoginData: "",
  OTPData:""
};

const AuthenticationReducer = (state = InitialState, action) => {
  switch (action.type) {
    case actionType.LOGIN:
      return {
        ...state,
        LoginData: "",
      };
    case actionType.LOGIN_SUCCESS:
      return {
        ...state,
        LoginData: action.payload,
      };
    case actionType.LOGIN_FAIL:
      return {
        ...state,
        LoginData: "error",
      };
      
      case actionType.OTP:
      return {
        ...state,
        OTPData: "",
      };
    case actionType.OTP_SUCCESS:
      return {
        ...state,
        OTPData: action.payload,
      };
    case actionType.OTP_FAIL:
      return {
        ...state,
        OTPData: "error",
      };
    default:
      return state;
  }
};
export default AuthenticationReducer;