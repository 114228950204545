import {all} from "redux-saga/effects"
import Authentication from "../Authentication/saga"
import Party from "../Party/saga"
import Items from "../Items/saga"
import Sale from '../Sale/saga'
import Master from '../Master/saga'
import Report from '../Report/saga'
import Common from '../Common/commonSaga'

export default function* rootSaga(){
    yield all([
        Authentication(),
        Items(),
        Party(),
        Sale(),
        Master(),
        Report(),
        Common()
        
        
        
    ])
}