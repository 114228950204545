import axios from "axios"
import { apiurl } from "configs/config"
import ApiClient from "./ApiClient";

class PartyService{
    constructor(callback){
        this.callback = callback;
    }
    async Party(payload) {
        const response = await axios.post(
            `${apiurl}/Api/Party/InsertParty`,payload
            
        ).then((response) => {
            return response.data;
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }

    async GetParty(payload) {
        const response = await axios.get(
            `${apiurl}/api/PartyType/GetAllPartyTypeWithTempleId?TempleId=${payload.TempleId}`,
            
        ).then((response) => {
            return response.data;
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }

   

    async ADDPARTY(payload) {
        const response = await axios.post(
            `${apiurl}/Api/Party/InsertParty`,payload
            
        ).then((response) => {
            return response.data;
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }


    async State(payload) {
        const response = await axios.get(
            `${apiurl}/api/Master/GetAllStates`,
            
        ).then((response) => {
            return response.data;
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }


    
    async GetAllParty(payload) {
        const response = await axios.get(
            `${apiurl}/Api/Party/GetAllParty?TempleId=${payload}`,
            
        ).then((response) => {
            return response.data;
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
    
    }


    
    
    export default new PartyService
