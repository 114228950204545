import axios from "axios";
import ApiClient from "./ApiClient";
import { apiurl } from '../configs/config';


class ItemService {
 constructor(callback) {
        this.callback = callback
    }

   
    async Additem(payload) {
        const response = await axios.post(
            `${apiurl}/api/Item/InsertItems`,
            payload,
        ).then((response) => {
            return response.data;
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
   
   
    async Listitem(payload) {
        const response = await axios.get(
            `${apiurl}/api/Item/SpGetAllItems?TempleId=${payload.TempleId}`,
            payload,
        ).then((response) => {
            return response.data;
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
   
   
   
    async Deleteitem(payload) {
        const response = await axios.get(
            `${apiurl}/api/Item/DeleteItem?TempleId=${payload.TempleId}&ItemId=${payload.ItemId}`,
            payload,
        ).then((response) => {
            return response.data;
            
        }).catch((error) => ({ error: JSON.stringify(error) }));
        return response;
    }
   
   
    
}
export default new ItemService();